<template>
  <section
    id="hero"
    style="background: gray; color: white; padding-top: 25px; padding-bottom: 25px;"
    class="d-flex flex-column align-center justify-center"
  >
    <h1 style="color: greenyellow;" class="mb-3">ESTAMOS EN SANTIAGO DE COMPOSTELA Y ORIHUELA COSTA</h1>
    <h4>
      Gracias al compromiso y dedicación de nuestro equipo, hemos logrado
      expandir nuestra marca Soluciones Merlín a diversas Provincias de España.
      Actualmente, disponemos de dos locales ubicados para ofrecer un servicio
      eficiente a nuestros clientes.
    </h4>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true }
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  }
};
</script>